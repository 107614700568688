
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import config from "./../env";
import React, { useState, useEffect } from 'react';
import { setData, getData, removeData } from './../utils/storage';


function ExchangeTokenPage() {
    console.debug('ExchangeTokenPage >> init');

    const location = useLocation();
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const state = queryParams.get('state');
    const code = queryParams.get('code');
    const scope = queryParams.get('scope');

    // Function to fetch data
    const fetchData = async () => {
        try {
            console.debug('ExchangeTokenPage >> fetchData');
            // Fetch data for last month and this month (Replace with actual API URLs)
            const response = await fetch(`${config.BE_URL}/athlete/auth/${code}`);
            const data = await response.json();

            console.debug('data', data);

            setData("id", data.id)

            // Set state with fetched data
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        console.debug('ExchangeTokenPage >> useEffect');

        fetchData();
    }, []);

    return (
        <div>
            <h1>Exchange Token Page</h1>
            <p>State: {state}</p>
            <p>Code: {code}</p>
            <p>Scope: {scope}</p>
        </div>
    );
}

export default ExchangeTokenPage;