import './App.css';
import HomePage from './views/HomePage';
import ExchangeTokenPage from './views/ExchangeTokenPage';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RunningAppPage from './views/RunningAppPage';
import PrivacyPolicyPage from './views/PrivacyPolicyPage';
import Header from './components/Header';
import { getData } from './utils/storage';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from "./env";


function App() {
  const [athlete, setAthlete] = useState(null);

  const fetchDataAthlete = async () => {
    const id = getIdFromQuery();
    // Fetch data for last month and this month (Replace with actual API URLs)
    const response = await fetch(`${config.BE_URL}/athlete/id/${id}`);
    const athleteData = await response.json();
    setAthlete(athleteData)
  }

  // Lấy id từ query string
  const getIdFromQuery = () => {
    try {
      // Load data from localStorage when the component mounts
      const id = getData('id');
      return id
    } catch (error) {
      return ''
    }
  };

  useEffect(() => {
    fetchDataAthlete()
  }, []);

  return (
    <Router>
      <Header athlete={athlete}/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/exchange_token" element={<ExchangeTokenPage />} />
        <Route path="/info" element={<RunningAppPage />} />
        <Route path="/policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
}


export default App;
