import React from 'react';
import { Dropdown } from 'react-bootstrap';

function LoadNewData({ onSyncToday, onSyncThisWeek, onSyncThisMonth }) {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Sync new Data
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={onSyncToday}>Hôm nay</Dropdown.Item>
                <Dropdown.Item onClick={onSyncThisWeek}>Tuần này</Dropdown.Item>
                <Dropdown.Item onClick={onSyncThisMonth}>Tháng này</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default LoadNewData;