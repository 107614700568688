import React from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { Container, Card } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const generateDaysInWeek = (data) => {
    // Check if data is an array and not empty
    if (!Array.isArray(data) || data.length === 0) {
        return []; // Return empty array if data is not an array or is empty
    }

    // Get the start of the week (Monday) based on the first date in the data
    const startOfWeek = moment(data[0]._id).startOf('isoWeek'); // ISO week starts on Monday
    const completeData = [];

    // Loop through each day of the week starting from Monday
    for (let day = 0; day < 7; day++) {
        const currentDate = moment(startOfWeek).add(day, 'days');
        const dateStr = currentDate.format('YYYY-MM-DD'); // Format as "YYYY-MM-DD"

        // Find the data for the current date, if available
        const foundData = data.find(item => item._id === dateStr);

        completeData.push({
            _id: dateStr,
            totalDistance: foundData ? foundData.totalDistance : 0 // If found, use its totalDistance; otherwise, set to 0
        });
    }

    return completeData;
};

const ChartWeek = ({ title, distanceData }) => {
    const formatData = generateDaysInWeek(distanceData);

    const labels = formatData.map(item => item._id);
    const dataValues = formatData.map(item => item.totalDistance / 1000);
    const totalDistanceSum = formatData.reduce((sum, item) => sum + item.totalDistance, 0);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Tổng số KM',
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                data: dataValues,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: 'Số KM mỗi ngày trong tuần',
            },
            legend: {
                display: true,
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Ngày',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Số KM',
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <Container className="mt-4">
            <Card>
                <Card.Header as="h5">{title} {(totalDistanceSum/1000).toFixed(2)} KM</Card.Header>
                <Card.Body>
                    <Bar data={data} options={options} />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ChartWeek;