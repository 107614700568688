// env.js
const config = {
    API_KEY: "your_api_key_here",
    FE_URL: window.location.origin,
    BE_URL: "https://apifunrun.ddthanhdat.top",
    AUTH_STRAVA: `https://www.strava.com/oauth/authorize?client_id=139211&response_type=code&redirect_uri=${window.location.origin}/exchange_token&approval_prompt=force&scope=activity:read_all,activity:read`,
    // thêm các biến khác nếu cần
};

export default config;
