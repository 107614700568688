// utils/storage.js

// Function to save data to localStorage
export function setData(key, value) {
    try {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
    } catch (e) {
        console.error('Error saving data to localStorage', e);
    }
}

// Function to get data from localStorage
export function getData(key) {
    try {
        const jsonValue = localStorage.getItem(key);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        console.error('Error reading data from localStorage', e);
        return null;
    }
}

// Function to remove data from localStorage
export function removeData(key) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.error('Error removing data from localStorage', e);
    }
}