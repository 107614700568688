import React from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function TrainingAppPage() {
  return (
    <Container className="my-5">
      {/* Header Section */}
      <header className="text-center mb-5">
        <h1 className="display-4">Chào mừng đến với ứng dụng hỗ trợ luyện tập!</h1>
        <h1 className="display-4">Welcome to the Training Support App!</h1>
        <p className="lead">
          Ứng dụng của chúng tôi giúp bạn ghi lại và theo dõi tiến trình luyện tập hàng ngày, từ quãng đường chạy bộ đến mục tiêu cá nhân.
        </p>
        <p className="lead">
          Our app helps you record and monitor your daily training progress, from running distances to personal goals.
        </p>
      </header>

      {/* Features Section */}
      <section className="mb-5">
        <Row>
          <Col md={4} className="text-center mb-4">
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Ghi chép chi tiết / Detailed Tracking</Card.Title>
                <Card.Text>
                  Theo dõi quãng đường, thời gian, và tốc độ để nắm rõ tiến trình luyện tập của bạn mỗi ngày.
                </Card.Text>
                <Card.Text>
                  Track distances, time, and speed to clearly understand your daily training progress.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="text-center mb-4">
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Mục tiêu luyện tập / Training Goals</Card.Title>
                <Card.Text>
                  Đặt mục tiêu luyện tập cá nhân và theo dõi sự tiến bộ của bạn qua từng tuần, từng tháng.
                </Card.Text>
                <Card.Text>
                  Set personal training goals and monitor your progress week by week, month by month.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="text-center mb-4">
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Cộng đồng cùng luyện tập / Training Community</Card.Title>
                <Card.Text>
                  Kết nối với các thành viên khác, chia sẻ thành tích và cùng nhau cải thiện sức khỏe.
                </Card.Text>
                <Card.Text>
                  Connect with other members, share achievements, and improve health together.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {/* Goal Section */}
      <section className="text-center mb-5">
        <h2 className="display-5">Thiết lập mục tiêu luyện tập của bạn</h2>
        <h2 className="display-5">Set Your Training Goals</h2>
        <p className="lead">
          Đặt ra những thử thách mới, theo dõi kết quả và nâng cao khả năng của bản thân mỗi ngày!
        </p>
        <p className="lead">
          Set new challenges, track your results, and improve your capabilities every day!
        </p>
      </section>

      {/* Disclaimer Section */}
      <section className="text-center">
        <Alert variant="secondary">
          <strong>Chú ý:</strong> Ứng dụng chỉ phục vụ mục đích luyện tập và không dùng cho mục đích thương mại.
        </Alert>
        <Alert variant="secondary">
          <strong>Note:</strong> This app is solely for training purposes and not intended for commercial use.
        </Alert>
      </section>
    </Container>
  );
}

export default TrainingAppPage;