import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function PrivacyPolicyPage() {
  return (
    <Container className="my-5">
      {/* Vietnamese Privacy Policy Section */}
      <section className="mb-5">
        <h2 className="display-5">Chính Sách Bảo Mật</h2>
        <Row>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>1. Bảo Mật Thông Tin</Card.Title>
                <Card.Text>
                  - <strong>Thu thập dữ liệu:</strong> Ứng dụng của chúng tôi chỉ thu thập những thông tin cơ bản cần thiết để cung cấp dịch vụ theo dõi và hỗ trợ luyện tập cá nhân, chẳng hạn như quãng đường chạy, tốc độ và mục tiêu của bạn. Chúng tôi không thu thập thông tin nhạy cảm nếu không có sự đồng ý của bạn.
                </Card.Text>
                <Card.Text>
                  - <strong>Mục đích sử dụng dữ liệu:</strong> Dữ liệu được thu thập chỉ dùng để theo dõi tiến trình luyện tập của bạn và cung cấp thông tin hữu ích về thành tích cá nhân. Dữ liệu sẽ không được sử dụng cho mục đích thương mại hay quảng cáo.
                </Card.Text>
                <Card.Text>
                  - <strong>Bảo mật dữ liệu:</strong> Chúng tôi cam kết bảo vệ dữ liệu của bạn, chỉ những nhân viên được ủy quyền mới có quyền truy cập. Các biện pháp an ninh được áp dụng để ngăn chặn truy cập trái phép và lạm dụng thông tin cá nhân của bạn.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>2. Xóa Thông Tin Người Dùng</Card.Title>
                <Card.Text>
                  - <strong>Yêu cầu xóa dữ liệu:</strong> Bạn có quyền yêu cầu xóa thông tin cá nhân của mình bất kỳ lúc nào. Vui lòng liên hệ với chúng tôi qua [email hỗ trợ/liên kết website]. Chúng tôi sẽ xử lý yêu cầu của bạn và xóa thông tin của bạn khỏi hệ thống trong vòng 30 ngày.
                </Card.Text>
                <Card.Text>
                  - <strong>Xóa vĩnh viễn:</strong> Sau khi yêu cầu xóa được xử lý, tất cả thông tin cá nhân của bạn, bao gồm lịch sử luyện tập, mục tiêu và các hồ sơ tiến trình, sẽ bị xóa vĩnh viễn khỏi máy chủ của chúng tôi. Quy trình này là không thể phục hồi và đảm bảo rằng không có dữ liệu nào được giữ lại sau khi xóa.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>3. Sự Đồng Ý Của Người Dùng</Card.Title>
                <Card.Text>
                  Bằng cách sử dụng ứng dụng này, bạn đồng ý với chính sách bảo mật này. Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về chính sách dữ liệu hoặc thông tin cá nhân của bạn, xin vui lòng liên hệ với chúng tôi.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>4. Cập Nhật Chính Sách</Card.Title>
                <Card.Text>
                  Chúng tôi có thể cập nhật chính sách bảo mật này để tuân thủ các quy định pháp luật và bảo mật mới nhất. Người dùng sẽ được thông báo về các thay đổi quan trọng.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {/* English Privacy Policy Section */}
      <section className="mb-5">
        <h2 className="display-5">Privacy Policy</h2>
        <Row>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>1. Data Privacy</Card.Title>
                <Card.Text>
                  - <strong>Data Collection:</strong> Our app collects only the minimal data necessary to provide personal training tracking services, such as your running distance, speed, and goals. We do not collect sensitive personal information without your explicit consent.
                </Card.Text>
                <Card.Text>
                  - <strong>Purpose of Data Use:</strong> The collected data is solely used to track your training progress and offer useful insights on personal achievements. No data will be used for commercial or advertising purposes.
                </Card.Text>
                <Card.Text>
                  - <strong>Data Security:</strong> We are committed to protecting your data. Only authorized personnel can access your information, and we implement security measures to prevent unauthorized access or misuse of your personal data.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>2. User Data Deletion</Card.Title>
                <Card.Text>
                  - <strong>Requesting Data Deletion:</strong> You have the right to request deletion of your personal information at any time. Please contact us via [support email/website link]. We will process your request and delete your information from our system within 30 days.
                </Card.Text>
                <Card.Text>
                  - <strong>Permanent Deletion:</strong> Once a deletion request is processed, all your personal data, including training history, goals, and progress records, will be permanently removed from our servers. This process is irreversible and ensures that no data is retained after deletion.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>3. User Consent</Card.Title>
                <Card.Text>
                  By using this app, you consent to this Privacy Policy. If you have any questions or concerns regarding our data practices or your personal information, please reach out to us.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>4. Policy Updates</Card.Title>
                <Card.Text>
                  We may update this policy to comply with the latest legal and security requirements. Users will be notified of significant changes.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default PrivacyPolicyPage;