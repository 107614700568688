import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Container, Card } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


// Extract the labels and data

const generateDaysInMonth = (data) => {
    // Check if data is an array and not empty
    if (!Array.isArray(data) || data.length === 0) {
        return []; // Return empty array if data is not an array or is empty
    }

    const firstDate = new Date(data[0]._id); // Convert the date string to a Date object
    const year = firstDate.getFullYear();
    const month = firstDate.getMonth(); // Month is 0-based

    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the last day of the month
    const completeData = [];

    for (let day = 1; day <= daysInMonth; day++) {
        const dateStr = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const foundData = data.find(item => item._id === dateStr);

        completeData.push({
            _id: dateStr,
            totalDistance: foundData ? foundData.totalDistance : 0 // If found, use its totalDistance; otherwise, set to 0
        });
    }

    return completeData;
};

const ChartMonth = ({ title, distanceData }) => {
    const formatData = generateDaysInMonth(distanceData)

    const labels = formatData.map(item => item._id);
    const dataValues = formatData.map(item => item.totalDistance / 1000);
    const totalDistanceSum = formatData.reduce((sum, item) => sum + item.totalDistance, 0);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Tổng số KM',
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                data: dataValues,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: 'Số KM mỗi ngày',
            },
            legend: {
                display: true,
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Ngày',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Số KM',
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <Container className="mt-4">
            <Card>
                <Card.Header as="h5">{title} {(totalDistanceSum/1000).toFixed(2)} KM</Card.Header>
                <Card.Body>
                    <Bar data={data} options={options} />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ChartMonth;