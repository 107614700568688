
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import ChartMonth from './../components/ChartMonth';
import ChartWeek from './../components/ChartWeek';
import Header from './../components/Header';
import LoadNewData from './../components/LoadNewData';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import config from "./../env";
import { setData, getData, removeData } from './../utils/storage';


function HomePage() {
  // Initialize states for lastMonth and thisMonth
  const [lastMonth, setLastMonth] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [lastWeek, setLastWeek] = useState([]);

  // Lấy id từ query string
  const getIdFromQuery = () => {
    try {
      // Load data from localStorage when the component mounts
      const id = getData('id');
      return id
    } catch (error) {
      return ''
    }
  };



  const syncToday = () => {
    const startOfToday = moment().startOf('day').unix();
    console.log("Syncing data for today from:", startOfToday);
    syncData(startOfToday)
  };

  const syncThisWeek = () => {
    const startOfWeek = moment().startOf('isoWeek').unix();
    console.log("Syncing data for this week from:", startOfWeek);
    syncData(startOfWeek)
  };

  const syncThisMonth = () => {
    const startOfMonth = moment().startOf('month').unix();
    console.log("Syncing data for this month from:", startOfMonth);
    syncData(startOfMonth)
  };

  const syncData = async (after) => {
    const id = getIdFromQuery();
    // Fetch data for last month and this month (Replace with actual API URLs)
    const response = await fetch(`${config.BE_URL}/athlete/id/${id}/activities/sync?after=${after}`);
    const athleteData = await response.json();
    console.log('athleteData', athleteData);
    // setAthlete(athleteData)
  }


  // Function to fetch data
  const fetchData = async () => {
    try {
      const id = getIdFromQuery();

      // Fetch data for last month and this month (Replace with actual API URLs)
      const lastMonthResponse = await fetch(`${config.BE_URL}/athlete/id/${id}/activities/lastmonth`);
      const lastMonthData = await lastMonthResponse.json();

      const thisMonthResponse = await fetch(`${config.BE_URL}/athlete/id/${id}/activities/thismonth`);
      const thisMonthData = await thisMonthResponse.json();

      const thisWeekResponse = await fetch(`${config.BE_URL}/athlete/id/${id}/activities/thisweek`);
      const thisWeekData = await thisWeekResponse.json();

      const lastWeekResponse = await fetch(`${config.BE_URL}/athlete/id/${id}/activities/lastweek`);
      const lastWeekData = await lastWeekResponse.json();

      // Set state with fetched data
      setLastMonth(lastMonthData);
      setThisMonth(thisMonthData);
      setThisWeek(thisWeekData);
      setLastWeek(lastWeekData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div className="App mx-5">
      <LoadNewData
        onSyncToday={syncToday}
        onSyncThisWeek={syncThisWeek}
        onSyncThisMonth={syncThisMonth}
      />

      <div className="row">
        <div className="col-md-6 mb-4">
          <ChartMonth title="Tháng trước" distanceData={lastMonth} />
        </div>
        <div className="col-md-6 mb-4">
          <ChartMonth title="Tháng này" distanceData={thisMonth} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-4">
          <ChartWeek title="Tuần trước" distanceData={lastWeek} />
        </div>
        <div className="col-md-6 mb-4">
          <ChartWeek title="Tuần này" distanceData={thisWeek} />
        </div>
      </div>
    </div>
  );
}


export default HomePage;